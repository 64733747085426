<template>
    <div class="loading">
        loading
    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>