<template>
   <div>
     <transition name="fade">
      <article v-if="risorsa && (risorsa.published == 1 || isAdmin)">

            <header>
                <router-link :to="{ name: 'Lezione', params: { lezioneId: $route.params.lezioneId}}" class="indietro">
                <svg class="icon" width="16" height="16"><use xlink:href="#back"/></svg>
                Indietro</router-link>
                <h2 v-if="risorsa.nome">{{ risorsa.nome }} <span class="badge badge--ligthgray" v-if="risorsa.published == 0">BOZZA</span></h2>
            </header>

            <div class="iframe-container" v-if="risorsa.tipo == 'video' && risorsa.link">
                  <div class="iframe-container-header">{{ risorsa.nome }} </div>
                  <iframe frameborder="0" id="vid" class="iframevideo" allowfullscreen
                          :src="getId(risorsa.link)">
                  </iframe>                             
            </div>

            <div class="allegato-container" v-if="risorsa.tipo == 'allegato' && risorsa.link">
                <a :href="risorsa.link" target="_blank">{{risorsa.nome}}</a>                         
            </div>
            
            <div v-html="risorsa.descrizione"></div>
      </article>
     </transition>

    </div>

</template>

<script>
import Loading from '@/components/Loading.vue'
import { getIdFromUrl } from 'vue-youtube'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'Risorsa',

  data: () => {
    return {
      risorsa: null,
      title: ""
    }
  },

  metaInfo() {
        return {
            title: this.title,
        };
   },


   computed: {

    ...mapGetters([
      'isAdmin',
    ])

  },
  
  components: {
      Loading
  },


  watch: {
      '$route.params': {
          handler(newValue) {
              const { risorsaId } = newValue
              this.loadRisorsa(risorsaId)
          },
          immediate: true,
      }
  },


  methods: {

      loadRisorsa(risorsaId) {
                this.risorsa = null
                const userData = JSON.parse(localStorage.getItem('user'))
                const token = userData['token']
                const config = {
                  headers: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
                };

                axios.get('risorse/' + risorsaId, config, { timeout: 2})
                .then((response) => {
                    this.risorsa = response.data
                    this.nome = response.data.nome;
                    localStorage.setItem("risorsaid", JSON.stringify(response.data.id))
                })
                .catch(e => {
                    console.log(e)
                })
      },

      getId (link) {
            return 'https://www.youtube-nocookie.com/embed/'+getIdFromUrl(link)+'?=showinfo=0&modestbranding=1&autohide=2&showsearch=0&cc_load_policiy=3&rel=0';
     },
  },


  beforeMount() {
    const risorsaId = this.$route.params.risorsaId
    this.loadRisorsa(risorsaId)
  },

  beforeDestroy() {
    this.risorsa = null
  },



}
</script>
